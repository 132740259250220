.notice-message {
  background-color: rgba($colorAccent1, 0.8);
  color: white;
  border: 1px solid $colorAccent1;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
}

.search-filters-wrapper {
  margin-bottom: 48px;
  .filter-title {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #073881;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .month-filter {
    margin-right: 30px;
  }

  .month-filter,
  .year-filter {
    background-color: #fff;
    width: 255px;
    height: 40px;
    border: none;
  }
}

.commandes-vetements-wrapper {
  padding-top: 65px;

  .hero-wrapper {
    padding-top: 0;
  }

  .commande-title {
    display: block;
    text-align: center;
    margin: 90px 0 50px 0;
  }

  .vetements-form {
    display: block;

    &.modif-form {
      padding-bottom: 40px;
    }

    hr {
      border-top: 2px solid white;
    }

    .vetements-form-container {
      max-width: 1170px;
      padding: 0 30px 80px;
      margin: 64px auto;
      margin-bottom: 100px;

      &.modif-vetements {
        margin-bottom: 0;
        padding-bottom: 0;

        .form-steps {
          margin-bottom: 0;

          .input-formats {
            min-height: inherit;
          }
        }

        &:last-child {
          margin-bottom: 64px;
          padding-bottom: 64px;

          .form-steps {
            margin-bottom: 64px;

            .input-formats {
              min-height: 705px;
            }
          }
        }

        &:nth-child(n + 2) {
          margin-top: 0;
          padding-top: 0;
        }
      }

      .open-accordion {
        position: relative;
        background-color: white;
        margin-top: 20px;
        text-align: center;
        border-radius: 6px;

        p {
          font-family: $fontTitle;
          color: $colorAccent3;
          font-size: 23px;
        }

        img {
          display: block;
          position: absolute;
          top: 50%;
          right: 40px;
          display: inline-block;
          max-width: 23px;
          transform: translate(0, -50%);

          &.rotate {
            transform: rotate(90deg);
            top: 40%;
          }
        }
      }
    }

    .form-steps {
      margin: 64px auto;

      &.step-one {
        max-width: 540px;
      }

      &.step-two {
        max-width: 1000px;
      }

      .small-title {
        color: $colorAccent1;
        font-family: $fontTitleRock;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
      }

      .error-message {
        display: none;
        margin-top: 20px;
        font-size: 18px;
        font-family: $fontText;
        color: #e80505;
        text-align: center;
      }

      .medium-title {
        font-family: $fontTitleRock;
        color: $colorAccent4;
        text-align: center;
        font-weight: bold;
        font-size: 33px;
        line-height: 44px;
        margin: 0 0 50px;
      }

      .steps-input-container {
        margin-top: 34px;
      }
    }

    .input-radio-wrapper {
      min-height: 70px;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        padding: 0 20px;
      }

      .input-radio {
        position: relative;
        background-color: #fff;
        border-top: 1px solid #dfdfdf;
        border-left: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        border-right: 1px solid #dfdfdf;

        padding: 0;

        input[type="radio"]:checked ~ .input-background {
          background-color: #333;
          border-top: 1px solid #333;
          border-left: 1px solid #333;
          border-bottom: 1px solid #333;
          border-right: 1px solid #333;
        }

        input[type="radio"]:checked ~ label {
          color: #fff;
        }

        &:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-right: none;

          input[type="radio"]:checked ~ .input-background {
            background-color: #333;
            border-right: none;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }

        &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-left: none;

          input[type="radio"]:checked ~ .input-background {
            background-color: #333;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-left: none;
          }
        }

        .input-background {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 10;
        }

        input[type="radio"] {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;

          &:checked {
            opacity: 1;
          }
        }

        label {
          display: block;
          position: relative;
          padding: 25px;
          font-family: $fontTextLight;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          z-index: 30;
        }
      }
    }

    .select-disciplines-wrapper {
      display: block;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        padding: 0 20px;
      }

      .select-disciplines,
      .select-category,
      .select-partenaires,
      .select-couleur {
        font-family: $fontTextLight;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        background-color: #fff;
        border: none;
        width: 100%;
        min-width: 100%;
        box-sizing: border-box;
        margin: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        padding: 20px;
        appearance: none;
        background-image: url("/img/icons/arrow-down.svg");
        background-repeat: no-repeat, repeat;
        background-position: right 20px top 50%, 0 0;
        background-size: 0.65em auto, 100%;

        .select-default {
          color: #8398bf;
        }
      }
    }
    .input-disciplines-wrapper {
      input {
        font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial",
          "sans-serif";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        width: 100%;
        border: none;
        border-radius: 6px;
        min-height: 59px;
        padding: 20px;
        background-color: white;
      }
    }

    .input-formats {
      display: block;
      margin-bottom: 20px;
      min-height: 705px;
      clear: both;

      @media (max-width: 767px) {
        padding: 0 20px;
      }

      .format-wrapper {
        display: inline-block;
        width: calc(100% / 3 - 23px);
        text-align: left;
        position: relative;

        background-color: #ffffff;
        border-radius: 3px;
        padding: 38px 20px;

        margin-right: 30px;
        margin-bottom: 30px;
        text-decoration: none;

        &:nth-child(3n + 3) {
          margin-right: 0;
        }

        &.is-dead {
          display: none;
          width: 0;
          height: 0;
          padding: 0;
          margin: 0;
        }

        &.is-disabled {
          background: $light;
          pointer-events: none;

          .format-wrapper-content {
            opacity: 0.4;
            user-select: none;
          }
        }

        @media (max-width: 767px) {
          display: block;
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }

        .format-wrapper-content {
          cursor: pointer;
        }

        .logo-wrapper {
          min-height: 80px;
          margin: 10px auto;

          img {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 198px;
          }
        }

        .format-final-row {
          display: flex;
          align-items: center;
        }

        .add-button {
          display: block;
          width: 100%;
          max-width: 25px;
          margin-left: auto;
        }

        input[type="number"] {
          display: block;
          margin: 0 auto;
          margin-bottom: 10px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          max-width: 60px;
          height: 40px;
          padding: 20px 16px;
          border-radius: 3px;
          text-align: center;

          font-size: 18px;
          font-weight: bold;
          font-family: $fontText;

          &::placeholder {
            color: $colorAccent4;
            text-align: center;
          }

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .format-title {
          font-family: $fontTitleRock;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          text-align: left;
          color: $colorAccent4;
          text-transform: none;
          margin-bottom: 0;
        }

        h3 {
          display: block;
          font-family: $fontTextLight;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $colorAccent4;
          margin: 5px 0 0;
        }

        .format-price {
          font-family: $fontTitleRock;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: $colorAccent1;
          margin: 0;
        }

        .format-colors {
          font-family: $fontTextLight;
          margin: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $colorAccent4;
        }

        .add-btn {
          margin: 20px auto 0;
          max-width: 106px;
        }

        .sports-icon {
          position: absolute;
          top: 18px;
          right: 18px;
          max-width: 35px;
        }
      }
    }

    .format-selected {
      display: flex;
      width: 100%;

      .ind-submit {
        display: block;
        max-width: 180px;
        margin: 56px auto 0;

        &:disabled {
          background: #c6c6c6;
          outline: none;
          border: #c6c6c6;
        }
      }

      .clothes-selector-wrapper {
        display: none;
        margin-right: 10px;

        &.is-active {
          display: block;
        }

        .clothes-tb-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          width: 110px;
          height: 120px;
          background: $white;
          padding: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &.is-active,
          &:hover {
            background: $colorAccent4;
          }

          .clothes-tb {
            max-width: 100%;
          }
        }
      }

      .product-wrapper {
        position: relative;
        display: none;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 3px;
        padding: 45px;
        margin-bottom: 45px;

        &.is-active {
          display: flex;
        }

        .image-section,
        .infos-section {
          display: block;
          width: 100%;
          max-width: 500px;

          img {
            display: block;
            width: 100%;
            min-width: 357px;
          }

          .colors-wrapper {
            display: flex;
            border: 1px solid #e6e6e6;
            border-radius: 3px;
            flex-wrap: wrap;

            .color-checkbox {
              display: flex;
              align-items: center;
              width: 100%;
              max-width: 50%;
              padding: 15px 13px;
              border-bottom: 1px solid #e6e6e6;
              cursor: pointer;
              transition: all 0.3s ease-in-out;

              &.is-disabled,
              &.full-disable {
                pointer-events: none;
                opacity: 0.5;
              }

              &:hover,
              &.is-selected {
                background-color: #f4f4f4;
              }

              &:nth-child(odd) {
                border-right: 1px solid #e6e6e6;
              }

              &:last-child {
                border-bottom: none;
              }

              .color-box {
                margin-right: 10px;

                &.blanc {
                  border: 1px solid #a2a2a2;
                }
              }

              p {
                display: block;
                font-family: $fontText;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 130%;
                color: $colorAccent4;
                margin: 0;
              }
            }
          }

          .product-title {
            display: block;
            width: 100%;
            text-align: left;
            font-family: $fontTextLight;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: $colorAccent4;
            margin: 0;
          }

          .product-subtitle {
            display: block;
            width: 100%;
            text-align: left;
            font-family: $fontText;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;

            color: $colorAccent4;
            margin-top: 0;
            margin-bottom: 18px;
          }

          .product-price {
            display: block;
            width: 100%;
            text-align: left;
            font-family: $fontTextLight;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: $colorAccent1;
          }

          .product-description {
            display: block;
            width: 100%;
            text-align: left;
            font-family: $fontText;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: $colorAccent4;
            margin-top: 0;
            margin-bottom: 37px;
          }

          .sizes-color-text {
            display: block;
            font-family: $fontText;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            color: #073881;
            margin-top: 8px;
            margin-bottom: 0;
          }

          .sizes-wrapper {
            display: flex;
            width: 100%;
            border: 1px solid #e6e6e6;
            border-radius: 3px;
            margin-top: 30px;
            overflow: hidden;

            .size-wrapper {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              max-width: calc(100% / 8);

              .size-name {
                display: block;
                text-align: center;
                width: 100%;

                font-family: $fontTextLight;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                text-align: center;

                color: #ffffff;
                background: #073881;
                margin: 0;
                padding-top: 12px;
                padding-bottom: 12px;
                border-bottom: 1px solid #e6e6e6;
              }

              input {
                width: 100%;
                border: none;

                border-right: 1px solid #e6e6e6;

                padding-top: 12px;
                padding-bottom: 12px;

                font-family: $fontTextLight;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #073881;

                &::placeholder {
                  font-family: $fontTextLight;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  text-align: center;
                  color: #073881;
                }
              }
            }
          }
        }

        .image-section {
          margin-right: 78px;
        }
      }
    }

    .cart-summary {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 30px 15px;

      background: $colorAccent4;
      z-index: 2;

      .cart-summary-container {
        max-width: 1170px;
        margin: auto;
      }

      .cart-price-summary {
        &.multiple-summary {
          display: flex;
          justify-content: space-between;
          margin: 15px 0 0;

          p {
            margin: 0 15px 0 0;
            font-family: $fontText;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            color: $white;

            &.sub-price {
              font-weight: normal;
            }
          }
        }

        .price-total {
          min-width: 450px;
        }

        .big-title {
          margin: 0;
          color: $light;
          font-family: $fontTitleRock;
          text-transform: uppercase;
          text-align: left;
          line-height: 1;
          margin-top: 20px;
        }

        a {
          color: $light;
          text-decoration: underline;
        }
      }

      .cart-btn-wrapper {
        float: right;
        text-align: right;

        &.multiple-summary {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .big-title {
            margin: 20px 30px 0 0;
            font-size: 25px;
            line-height: 150%;
            color: $white;
          }
        }

        .site-btn {
          display: inline-block;
          margin-top: 20px;
          background: $light;
          color: $colorAccent4;
          border: none;
          max-width: 205px;

          &.is-reverted {
            background: transparent;
            border: 1px solid $light;
            color: $light;

            &:hover {
              opacity: 1;
              background: $light;
              color: $colorAccent4;
            }
          }

          &:hover,
          &[disabled]{
            opacity: 0.8;
          }

          &.is-hidden {
            display: none;
          }
        }
      }
    }

    .form-bottom {
      display: block;
      position: relative;
      min-height: 55px;
      margin-bottom: 70px;

      @media (max-width: 767px) {
        min-height: 155px;
        margin-bottom: 70px;
        padding: 0 20px;
      }

      .total-wrapper {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: black;
        margin: 0;
        transform: translate(0, -50%);

        @media (max-width: 767px) {
          display: block;
          position: relative;
          text-align: center;
        }

        span {
          color: $colorAccent1;
        }
      }

      .form-vetements-submit {
        display: inline-block;
        position: absolute;
        right: 0;
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        max-width: 180px;

        @media (max-width: 767px) {
          display: block;
          position: relative;
          width: 100%;
          max-width: 100%;
          margin-bottom: 40px;
        }
      }
    }
  }
}

.status-resume {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $white;
  font-family: $fontText;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  background-color: $colorAccent3;
  padding: 10px 20px;
  border-radius: 35px;

  &.encours {
    background-color: #f58e57;
  }

  &.preparation {
    background-color: #db2e2e;
  }

  &.livre {
    background-color: #56d279;
  }

  &.big {
    top: 120px;
    right: 0;
  }
}

.dashboard-vetements-wrapper {
  display: block;
  max-width: 1000px;
  margin: 0 auto;
  padding: 100px 0 0 0;
  clear: both;
  position: relative;

  @media (max-width: 1023px) {
    padding: 100px 20px;
  }

  .dashboard-title {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: $colorAccent4;
  }

  .dashboard-subtitle {
    margin-top: 0;
    margin-bottom: 78px;
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: $colorAccent4;
  }

  .btns-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-left: calc(100% - 480px);
    margin-bottom: 75px;

    &.is-admin {
      margin-left: calc(100% - 680px);
    }

    a {
      display: inline-block;
      max-width: 220px;
      margin-right: 0 auto;

      &:not(:first-child) {
        margin-left: 35px;
      }
    }
  }

  .dashboard-menu {
    display: block;
    position: relative;
    background-color: #fff;
    border-radius: 3px;
    padding: 21px 30px;
    margin-bottom: 20px;

    .menu-price {
      display: inline-block;
      margin-right: 60px;

      @media (max-width: 1024px) {
        text-align: center;
        width: calc(100% / 4 - 3px);
        margin-right: 0;
      }

      @media (max-width: 599px) {
        text-align: left;
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      .price-title {
        font-family: $fontTextLight;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin: 0;

        @media (max-width: 766px) {
          font-size: 14px;
        }

        @media (max-width: 599px) {
          display: inline-block;
          margin-right: 10px;
        }
      }

      p {
        font-family: $fontTextLight;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin: 0;
        color: $colorAccent1;

        @media (max-width: 766px) {
          font-size: 14px;
        }

        @media (max-width: 599px) {
          display: inline-block;
        }
      }
    }

    .menu-final {
      display: block;
      position: absolute;
      top: 20px;
      right: 30px;
      width: 375px;
      clear: both;

      @media (max-width: 1024px) {
        position: relative;
        top: auto;
        right: auto;
        margin: 0 auto;
        margin-top: 20px;
      }

      @media (max-width: 599px) {
        width: auto;
      }

      .recommande {
        position: absolute;
        display: inline-block;
      }

      .total-final {
        display: inline-block;
        margin: 5px 16px 0px 0;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 150%;

        @media (max-width: 599px) {
          display: block;
          width: 100%;
          margin-bottom: 20px;
        }

        span {
          color: $colorAccent1;
        }
      }

      .cta-final {
        display: inline-block;
        max-width: 195px;
        margin: 0;
        float: right;

        @media (max-width: 599px) {
          display: block;
          width: 100%;
          max-width: inherit;
        }
      }
    }
  }
}

.total-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(black, 0.6);
  z-index: 95;
}

.resume-dashboard {
  width: 100%;
  text-align: center;
  margin-bottom: 75px;

  &.is-modal {
    position: fixed;
    max-width: 720px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }

  .resume-header {
    background-color: $colorAccent4;

    th {
      padding: 15px 0;
      color: white;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  .resume-body {
    background-color: $white;

    &.delimitation {
      border-top: 30px solid rgba($colorAccent4, 0);
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }

    &.lot-1,
    .lot-3,
    .lot-5,
    .lot-7,
    .lot-9,
    .lot-11,
    .lot-13,
    .lot-15,
    .lot-17,
    .lot-19,
    .lot-21,
    .lot-23,
    .lot-25,
    .lot-27,
    .lot-29 {
      background-color: $white;
    }

    &.lot-2,
    .lot-4,
    .lot-6,
    .lot-8,
    .lot-10,
    .lot-12,
    .lot-14,
    .lot-16,
    .lot-18,
    .lot-20,
    .lot-22,
    .lot-24,
    .lot-26,
    .lot-28,
    .lot-30 {
      background-color: #ebf2fc;
    }

    td {
      padding: 15px 0;
      color: black;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;

      &.invisible {
        opacity: 0;
      }

      &.medium-title-table {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: left;

        color: #073881;
        padding-left: 15px;
      }

      &.medium-text-table {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: right;

        color: $colorAccent1;
        padding-right: 15px;
      }

      &.big-title-table {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        text-transform: uppercase;
        text-align: left;

        /* Dark blue */

        color: #073881;
        padding-left: 15px;
      }

      &.big-text-table {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        text-transform: uppercase;
        text-align: right;

        /* Dark blue */

        color: $colorAccent1;
        padding-right: 15px;
      }
    }
  }

  .resume-commande {
    display: inline-block;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    min-width: 320px;
    min-height: 230px;
    padding: 20px;
    width: calc(33.33333333% - (40px / 3));

    margin-right: 20px;
    margin-bottom: 20px;

    @media (max-width: 1023px) {
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media (max-width: 766px) {
      margin-right: 0;
      margin-bottom: 20px;
      min-width: 100%;
      // min-height: 100%;
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    .locker {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 26px;
      height: 35px;
      transform: translate(-50%, -50%);
    }

    .resume-global {
      display: block;
      width: 100%;
      text-align: center;

      .resume-each {
        display: inline-block;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        padding-top: 55px;
        min-width: 75px;

        img {
          display: block;
          width: 100%;
          max-width: 30px;
          margin: 0 auto;
        }

        p {
          text-align: center;
        }
      }
    }

    .specs-resume {
      position: absolute;
      top: 20px;
      left: 20px;

      .spec-title {
        font-family: $fontTitleRock;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 3px 0;
      }

      p {
        font-family: $fontTitleRock;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: $colorAccent4;
        margin: 0;
      }
    }

    .logo-resume {
      position: absolute;
      right: 20px;

      img {
        display: block;
        width: 25px;
        height: 25px;
      }
    }

    .resume-name {
      position: absolute;
      bottom: 20px;

      p {
        font-family: $fontTextLight;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $colorAccent4;
        margin: 0;
      }
    }

    .resume-price {
      position: absolute;
      bottom: 20px;
      right: 20px;

      p {
        font-family: $fontTextLight;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin: 0;
        color: $colorAccent1;
      }
    }
  }

  .add-commande {
    display: inline-block;
    position: relative;
    background-color: #000;
    color: #fff;
    width: calc(33.33333333% - (40px / 3));
    border-radius: 10px;

    text-align: center;
    min-width: 320px;
    max-height: 230px;
    padding: 0;

    margin-right: 20px;

    @media (max-width: 1023px) {
      max-width: calc(50% - 10px);

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media (max-width: 766px) {
      margin-right: 0;
      margin-bottom: 20px;
      min-width: 100%;
      max-height: 230px;
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    a {
      display: block;
      min-width: 320px;
      min-height: 230px;
      max-height: 230px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $colorAccent1;

        i {
          background-color: $colorAccent1;
        }
      }

      i {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -35%);
        background-color: white;
        color: black;
        font-size: 30px;
        border-radius: 50%;
        padding: 4px;
        min-height: 50px;
        transition: all 0.3s ease-in-out;

        &:before {
          margin-top: 5px;
        }
      }

      .add-title {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -55%);
        width: 100%;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
