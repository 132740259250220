// --------------------------------------------------
// Header
// --------------------------------------------------
.sub-menu-account {
  position: absolute;
  top: 110px;
  right: 50px;
  min-width: 100px;
  min-height: 50px;

  background-color: #fff;
  display: none;
  z-index: 1000;
  padding: 20px;
  border-radius: 3px;
  z-index: 60;

  @media (max-width: 599px) {
    top: 70px;
    right: 60px;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -15px;
    right: 5px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

    border-bottom: 15px solid white;

    @media (max-width: 599px) {
      right: 35px;
    }
  }

  &.is-active {
    display: block;
  }

  .logout-btn {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }
}

.main-header {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 57px;
  padding: 15px 0;
  z-index: 50;
  background: white;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform 0.7s .3s ease;
    transition: transform 0.7s .3s ease;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, .15);

    background: $dark;

    &.hide-nav-bar {
        -webkit-transform: translate(0, -100%);
        -ms-transform: translate(0, -100%);
        transform: translate(0, -100%);
        -webkit-backface-visibility: hidden;
    }
  }

  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    background: white;
  }

  .header-left, .header-right {
    width: 100px;
  }

  .header-logo {

    img {
      display: inline-block;
      height: 26px;

      @media (max-width: 1024px) {

        position: absolute;
        left: 40px;
        top: 50%;
        transform: translate(0, -50%);
        height: 20px;
      }
    }
  }

  .header-center {
    display: flex;
    color: $colorAccent1;

    @media (max-width: 1024px) {
      display: none;
    }

    .header-link {
      position: relative;
      min-width: 150px;
      padding: 0 20px;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: bold;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      color: $colorAccent4;
      margin: 0;
      transition: all 0.35s ease;

      &:hover {
        color: $colorAccent1;
      }

      @media (max-width: 1100px) {
        font-size: 14px;
        margin: 0 10px;
      }

      &.is-active:after {
        content: '';
        position: absolute;
        bottom: -21px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $colorAccent1;
      }
    }
  }

  .header-right {
    .hero-alert {
      position: relative;
      display: inline-block;
      margin-right: 30px;
      cursor: pointer;

      img {
        margin-bottom: 5px;
      }

      .alert {
        display: block;
        position: absolute;
        top: 2px;
        right: 2px;
        background-color: #F82558;
        border-radius: 1px;
        font-family: $fontText;
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        color: $white;
        max-width: 10px;
        max-height: 13px;
        text-align: center;
        padding: 2px;
        margin: 0;

        &.is-checked {
          display: none;
        }
      }
    }

    .hero-account {
      display: inline-block;
      position: relative;
      z-index: 30000;
      cursor: pointer;

      img,
      div {
        display: inline-block;
      }

      img {
        margin-right: 10px;
        margin-bottom: 5px;
      }

      .select-account {
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        background-color: #fff;
        border: none;
        min-width: 100px;
        box-sizing: border-box;
        margin: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url('/img/icons/arrow-down.svg');
        background-repeat: no-repeat, repeat;
        background-position: right 5px top 50%, 0 0;
        background-size: .65em auto, 100%;

        @media (max-width: 599px) {
          display: none;
        }
      }
    }
  }

  .mobile-menu-trigger {
    display: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url('/img/site/icon-burger.svg') no-repeat center center / contain;

    @media (max-width: 1024px) {
      display: block;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translate(0, -50%)
    }
  }
}
