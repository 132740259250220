// --------------------------------------------------
// Content - Pagination
// --------------------------------------------------

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 75px;

  @media (max-width: 1099px) {
    padding: 25px 20px;
  }

  a,
  div {
    display: block;
    height: 35px;
    padding: 9px 13px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0 8px;

    border: none;

    background: transparent;
    color: $dark;
    text-decoration: none;

    @extend .button-text;

    &.content-pagination-prev,
    &.content-pagination-next {
      span {
        display: block;
        color: $colorAccent1;
        font-family: $fontText;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

      }
    }

    &.content-pagination-prev {
      margin-right: 50px;

      span {
        &:before {
          content: url('/img/icons/blue-prev_arrow.svg');
          height: 16px;
          width: 16px;
          display: inline-block;
          margin-right: 15px;
        }

        &.is-disabled {
          opacity: .7;
        }
      }
    }

    &.content-pagination-next {
      margin-left: 50px;


      span {
        &:after {
          content: url('/img/icons/blue-next-arrow.svg');
          height: 16px;
          width: 16px;
          display: inline-block;
          margin-left: 15px;
        }

        &.is-disabled {
          opacity: .7;
        }
      }
    }

    &:hover {
      color: $colorAccent1;
    }

    &.is-current {
      background: $colorAccent1;
      color: #fff;
      border-radius: 50px;
    }

    &:last-child {
      border-right: 1px $light solid;
    }
  }
}
