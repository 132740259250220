.users-login {
  background-color: $colorAccent4;
  .login-wrapper {
    display: block;
    position: relative;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    background-color: $colorAccent4;
    background-position:  center center;

    &.signup-wrapper {
      overflow: scroll;
      height: 100%;
    }

    .login-overlay {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0, 0.8);
      z-index: 4;
      pointer-events: none;
    }

    .logo {
      display: block;
      position: relative;
      margin: 0 auto;
      padding-top: 5%;
      z-index: 99;
    }
  }
}

.form-wrapper,
.login-form-wrapper,
.signup-form-wrapper {
  display: block;
  position: relative;
  width: 100%;
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  padding-top: calc(58px + 63px);
  z-index: 99;
  top: 50%;
  transform: translate(0, -50%);

  //border-top: 8px solid $colorAccent1;
  z-index: 99;

  .login-text {
    color: $colorAccent1;
  }
}

.login-form,
.signup-form {
  display: block;
  margin: 0 auto;
  position: relative;
  text-align: center;
  padding: 10px 40px 40px;
  background: $light;
  border-radius: 10px;

  &.success-box {
    padding-bottom: 10px;
  }

  #rseq-ghost {
    display: none;
  }

  .message.notice {
    margin: 0;
  }

  .form-title {
    font-size: 33px;
    line-height: 49px;
    font-weight: normal;
    text-transform: uppercase;
    color: $colorAccent1;
    margin: 0 0 25px 0;
  }

  .medium-title {
    color: $colorAccent4;
  }

  .input-wrapper {
    position: relative;
    margin-bottom: 20px;

    img {
      position: absolute;
      top: 13px;
      left: 15px;
    }

    input,
    select {
      width: 100%;
      min-height: 50px;
      padding-left: 50px;
      border-radius: 3px;
      border: 1px solid #8398BF;
      color: $colorAccent4;
      background: $white;

      &::-webkit-input-placeholder {
        color: $colorAccent4;
      }

      &:-ms-input-placeholder {
        color: $colorAccent4;
      }

      &::placeholder {
        color: $colorAccent4;
      }
    }
  }

  .input-wrapper {
    select {
      height: 50px;
      background: $white;
      padding-left: 15px;
    }
  }

  .cta-acces {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    text-align: center;

    color: $colorAccent1;
  }

  input[type="submit"] {
    display: block;
    width: 100%;
    min-height: 50px;
    border-radius: 3px;
    border: none;
    color: white;
    background-color: $colorAccent1;
    margin-bottom: 20px;

    font-family: $fontText;
    font-size: 18px;
    line-height: 21px;

  }

  .bottom-form {
    display: block;
    width: 100%;
    margin-bottom: 40px;

    label,
    a {
      display: inline-block;
      width: 49%;

      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

    }

    label {
      color: #0F0F0F;
      input {
        margin-right: 10px;
        background: #FFFFFF;
        width: 15px;
        height: 15px;
        border: 1px solid #8398BF;
        box-sizing: border-box;
        border-radius: 3px;
      }
      text-align: left;
    }

    a {
      text-align: right;
      color: $colorAccent1;
    }
  }
}

.signup-form {
  .input-wrapper {
    input {
      padding-left: 18px;
    }
  }
}
