// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  border: 2px solid $colorAccent1;
  background: $colorAccent1;
  color: #fff;
  font-family: $fontText;

  font-size: 16px;
  line-height: 19px;
  letter-spacing: -.025em;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;
  -webkit-appearance: none;
  border-radius: 6px;
  margin-bottom: 0;

  &.grey {
    font-family: $fontTextLight;
    border: 2px solid #F4F4F4;
    background: #F4F4F4;
    color: black;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;

    &:hover {
      border: 2px solid $colorTextDark;
      background: $colorTextDark;
      color: white;
      text-decoration: none;
    }
  }

  &:hover {
    border: 2px solid $colorAccent2;
    background: $colorAccent2;
    color: #F4F4F4;
    text-decoration: none;
  }

  &.is-outlined {
    border: 1px solid $colorAccent1;
    background: transparent;
    color: $colorAccent1;
    font-family: $fontTitleSerif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;

    &:hover {
      border-color: $colorAccent3;
      color: $colorAccent3;
      opacity: 1;
    }

    &.reverted-hover {
      &:hover {
        border-color: $dark;
        color: $dark;
        opacity: 1;
      }
    }
  }

  &.is-reverted {
    background: transparent;
    border-color: $light;
  }

  &.is-bigger {
    min-width: 200px;
    padding: 15px 25px;
    font-size: 18px;
  }

  &.is-alternate {
    border-color: $colorAccent3;
    background: $colorAccent3;
  }
}

.site-link-btn {
  font-family: $fontText;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: $colorAccent1;
}

.bottom-button {
  &.is-outlined {
    border: 1px solid $colorAccent1;
    background: transparent;
    color: $colorAccent1;
    font-family: $fontTitleSerif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;

    &:hover {
      border-color: $colorAccent3;
      color: $colorAccent3;
      opacity: 1;
    }

    &.reverted-hover {
      &:hover {
        border-color: $dark;
        color: $dark;
        opacity: 1;
      }
    }
  }
}
