.export {
  overflow: auto;

  main {
    height: 100%;
  }
  .consultations-wrapper {
    padding-top: 80px;
    padding-bottom: 100px;

    .consultations-content {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      padding: 40px;
      border-radius: 10px;
      background-color: $white;
    }

    .consultations-title {
      font-family: $fontTitle;
      color: $white;
      margin-bottom: 50px;
      text-align: center;
    }

    .filters-wrapper {
      margin: 0 0 20px;
      padding: 0 0 20px;
      border-bottom: 1px solid $colorAccent5;
    }

    .filters {
      display: flex;
      gap: 40px;
      width: 100%;

      .filter {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        label {
          font-family: $fontTitle;
          font-size: 14px;
          font-weight: 500;
          color: $colorAccent5;
          margin-bottom: 5px;
        }

        select {
          width: 100%;
          max-width: 200px;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid $colorAccent5;
          background-color: $white;
          font-family: $fontTitle;
          font-size: 14px;
          font-weight: 500;
          color: $colorAccent5;
          appearance: none;
          cursor: pointer;
        }

        .filter-button {
          display: inline-block;
          width: 100%;
          max-width: 200px;
          padding: 10px;
          border-radius: 5px;
          border: none;
          font-family: $fontTitle;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.5;
          color: $white;
          text-align: center;
          background-color: $colorAccent5;
          cursor: pointer;
          transition: background-color .3s ease-in-out;

          &:hover {
            background-color: rgba($colorAccent5, .8);
          }
        }
      }
    }

    .inscriptions-listing {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      margin: 0 auto;
    }

    .inscriptions-empty {
      margin: 20px 0;
      font-family: $fontTitle;
      font-size: 22px;
      font-weight: 500;
      color: $colorAccent5;
      text-align: center;
    }

    .inscription-item {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      border-radius: 5px;
      border: 1px solid $colorAccent5;
      background-color: $white;

      .item-content {
        width: calc(100% - 100px);
      }

      .item-divider {
        width: 50%;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: $colorAccent5;
      }

      .item-participant {
        font-family: $fontTitle;
        font-size: 22px;
        font-weight: 500;
        color: $colorAccent5;
      }

      .item-text {
        font-family: $fontText;
        font-size: 14px;
        font-weight: 500;
        color: $colorAccent5;
        margin: 0;
      }

      .item-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        gap: 20px;
      }

      .item-button {
        width: 100px;
        padding: 10px;
        border-radius: 5px;
        border: none;
        text-align: center;
        font-family: $fontTitle;
        font-size: 14px;
        font-weight: 500;
        color: $white;
        background-color: $colorAccent1;
        cursor: pointer;
        transition: background-color .3s ease-in-out;

        &:hover {
          background-color: rgba($colorAccent1, .8);
        }
      }
    }

    .consultations-back {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: max-content;
      margin-bottom: 50px;
      padding: 10px;
      border-radius: 5px;
      font-family: $fontTitle;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      color: $white;
      text-align: center;
      text-decoration: none;
      background-color: $colorAccent5;
      transition: background-color .3s ease-in-out;

      &:hover {
        background-color: rgba($colorAccent5, .8);
      }
    }

    .registration-infos {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto 20px;
      padding-bottom: 20px;
      border-bottom: 2px solid $colorAccent5;
    }

    .registration-title {
      font-family: $fontTitle;
      font-size: 36px;
      font-weight: 500;
      color: $colorAccent5;
    }

    .registration-divider {
      width: 50%;
      height: 1px;
      margin-top: 10px;
      margin-bottom: 20px;
      background-color: $colorAccent5;
    }

    .registration-text {
      font-family: $fontText;
      font-size: 14px;
      font-weight: 500;
      color: $colorAccent5;
      margin: 0;
    }

    .registration-answers {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      margin: 0 auto;
    }

    .registration-answer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      border: 1px solid $colorAccent5;
      border-radius: 5px;

      h4 {
        margin-bottom: 20px;
        font-family: $fontTitle;
        font-size: 18px;
        font-weight: 500;
        color: $colorAccent5;
        text-decoration: underline;
      }

      p {
        margin: 0;
        font-family: $fontText;
        font-size: 14px;
        font-weight: 500;
        color: $colorAccent5;
      }

      a {
        display: inline-block;
        width: max-content;
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        font-family: $fontText;
        font-size: 14px;
        font-weight: 600;
        color: $white;
        text-align: center;
        text-decoration: none;
        background-color: $colorAccent1;
        transition: background-color .3s ease-in-out;

        &:hover {
          background-color: rgba($colorAccent1, .8);
        }
      }

      img {
        width: 100%;
        max-width: 400px;
        margin: 0 auto 20px;
      }

      video {
        width: 100%;
        max-width: 400px;
        margin: 0 auto 20px;
      }

      .answer-empty {
        color: #f44336;
      }
    }
  }
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 30px 0 0;

  & > *:not(.ellipsis):not(.current) {
    cursor: pointer;
  }

  .pagination-item {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $colorAccent1;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    transition: background-color .3s ease-in-out;

    &:hover {
      background-color: rgba($colorAccent1, .8);
    }
  }

  .current {
    color: $colorAccent1;
    font-weight: 600;
  }

  .prev,
  .next {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorAccent5;
    font-size: 20px;
    font-weight: 600;
    transition: background-color .3s ease-in-out;

    &:hover {
      color: rgba($colorAccent5, .8);
    }
  }
}
