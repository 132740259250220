// --------------------------------------------------
// Hero
// --------------------------------------------------

.hero-wrapper {
  display: block;
  position: relative;
  min-height: 200px;
  background-color: $colorAccent4;
  padding-top: 80px;
  background: url("/img/site/hero-home.png");
  z-index: 1;
  background-size: cover;

  &.no-background {
    background: none;
  }

  .hero-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($colorAccent4, 0.9);
    z-index: 4;
  }

  .hero-title {
    display: block;
    font-family: $fontTitle;
    position: relative;
    width: 100%;
    padding: 80px 20px;
    text-align: center;
    color: white;
    z-index: 10;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 74px;
    text-transform: uppercase;
  }
}

.section-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 80px 20px;
  max-width: 1378px;
  margin: 0 auto;
  margin-top: -135px;
  position: relative;
  z-index: 1000;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    overflow-y: scroll;
  }

  &.thanks-you {
    padding-top: 0;

    max-width: 320px;
    margin: 0 auto;
    margin-top: -45px;

    img {
      display: block;
      margin: 0 auto;
      width: 105px;
      height: 105px;
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      line-height: 60px;
      text-align: center;
      text-transform: uppercase;
      color: #008cd3;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 29px;
      text-align: center;
      color: #073881;
    }

    form {
      button {
        display: block;
        max-width: 189px;
        margin: 0 auto;
      }
    }

    a {
      display: block;
      max-width: 189px;
      margin: 0 auto;
    }
  }

  .section-card {
    position: relative;
    display: inline-block;
    width: calc((100% / 4) - 20px);
    min-height: 401.95px;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding: 37px 38px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1090px) {
      width: calc((100% / 2) - 20px);
    }

    @media (max-width: 768px) {
      display: block;
      width: 100%;
      margin: 0 auto 20px;

      &:last-child {
        margin-right: inherit;
      }
    }

    .section-logo {
      display: block;
      max-width: 89px;
      margin: 0 auto;
      margin-bottom: 19px;
      margin-top: 0;

      &.is-desabled {
        opacity: 0.5;
      }

      &.banniere {
        max-width: 112px;
      }

      &.chandail {
        max-width: 145px;
      }
    }

    .section-title {
      display: block;
      font-family: $fontTitleRock;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      text-transform: uppercase;
      color: #008cd3;
      text-align: center;
      margin-bottom: 19px;
      margin-top: 0;

      &.is-desabled {
        opacity: 0.5;
      }
    }

    .section-cta {
      display: block;
      width: 100%;

      .form-btn-commande {
        margin-bottom: 15px;
      }

      .comming-soon {
        //position: absolute;
        width: 100%;
        //max-width: 243px;
        bottom: 25px;
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        color: #008cd3;

        border-top: 1px solid $colorAccent1;
        border-bottom: 1px solid $colorAccent1;
        padding-top: 7px;
        padding-bottom: 7px;
        margin-top: 42px;
      }
    }
  }
}
