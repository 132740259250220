// --------------------------------------------------
// Intro
// --------------------------------------------------
.site-intro {
  .intro-wrapper {
    @extend .site-block-ptpb;

    .intro-heading {
      max-width: 800px;
      margin-top: 0;
      margin-bottom: 75px;
      color: $colorTextDark;

      font-size: 28px;

      @media only screen and (max-width: 1099px) {
        font-size: 28px;
        line-height: 1.4;
      }

      @media only screen and (max-width: 767px) {
        font-size: 22px;
      }
    }

    .intro-text {
      max-width: 445px;
      margin-top: 0;
      margin-bottom: 0;
      color: $colorAccent3;

      p {
        color: $colorAccent3;
      }
    }
  }
}

// --------------------------------------------------
// Content Cards Slider
// --------------------------------------------------
.site-content-card-slider {
  background: $light;

  &.is-dark {
    background: linear-gradient(180deg, #3D3F3F 0%, #2B2D2C 100%);
    color: #fff;

    .content-card-text {
      color: #fff;

      p {
        color: #fff;
      }
    }
  }

  .content-heading {
    max-width: 540px;
    margin-top: 0;
    margin-bottom: 75px;
  }

  .content-text {
    margin-top: 0;
    margin-bottom: 0;
  }



  .content-card-slider-wrapper {
    @extend .site-block-ptpb;

    .content-card-text {
      margin-top: 0;
      margin-bottom: 50px;
      max-width: 350px;
      color: #3F4040;

      @media (max-width: 767px) {
        margin-bottom: 35px;
      }

      &.is-wide {
        max-width: 520px;
      }
    }

    .content-card-text-twoCols {
      max-width: 520px;

      p {
        color: $colorAccent3;
      }
    }
  }

  .cards-slider {
    width: 100vw;
  }

  .cards-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }
}

// --------------------------------------------------
// Block Card
// --------------------------------------------------
.cards-slider,
.cards-wrapper {
  position: relative;
  margin-left: -15px;

  .site-card {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 35px 30px;
    margin: 0 15px 30px 15px;
    width: calc((100%/4) - (15px * 2));
    min-height: 300px;
    background: #FFFFFF;
    border: 1px solid #D1D2D4;
    border-radius: 6px;

    @media (max-width: 767px) {
      width: calc((100%/2) - (25px));

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media (max-width: 599px) {
      width: 100%;
      margin-right: 0;
    }

    &.is-dark {
      background: #3F5967;
      border: none;
      color: #fff;

      .card-heading,
      .card-text {
        color: #fff;
      }
    }

    &.flex-column-end {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;

      .card-text {
        margin-top: auto;
      }
    }
  }

  .card-icon {
    max-width: 40px;
    margin-top: 0;
    margin-bottom: 25px;
  }

  .card-heading {
    max-width: 110px;
    margin-top: 0;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.6;
    color: #3F4040;
  }

  .card-text {
    max-width: 195px;
    margin-top: auto;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    color: $colorAccent3;

    p {
      color: $colorAccent3;
    }

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .site-card-team {
    .card-icon {
      margin-bottom: 80px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    .card-small-img {
      position: relative;
      margin-bottom: 50px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;

      @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
      }

      img {
        display: block;
        max-width: inherit;
        width: 100%;
      }
    }

    .card-heading {
      margin-top: auto;
      margin-bottom: 0;
    }

    .card-text {
      margin-top: 0;
      margin-bottom: 0;
    }

    .site-link-btn {
      display: block;
      margin-top: 35px;
      margin-bottom: 0;
    }
  }
}

.cards-wrapper {
  padding-top: 45px;

  @media (max-width: 767px) {
    padding-top: 15px;
  }
}

// --------------------------------------------------
// Content Background Text
// --------------------------------------------------
.site-content-bg-text {
  position: relative;
  height: 30vw;
  margin-top: 50px;
  margin-bottom: 50px;
  background: url('/img/site/map.svg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;

  @media (max-width: 767px) {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .content-bg-wrapper {
    width: 100%;
    height: 100%;

    .content-bg-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 566px;
      margin: 0;
      text-align: center;
      font-style: italic;
      color: $colorAccent3;

      p {
        color: $colorAccent3;

        strong {
          color: $colorAccent3;
        }
      }

      @media (max-width: 767px) {
        max-width: 325px;
      }
    }
  }
}

// --------------------------------------------------
// Block CTA
// --------------------------------------------------
.site-content-cta {
  background: #F8F8F8;
  color: #3F4040;

  .content-cta-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @extend .site-block-ptpb;

    .content-cta-heading {
      margin-bottom: 100px;

      @media (max-width: 767px) {
        margin-bottom: 75px;
      }
    }

    .content-cta-upper-text {
      max-width: 450px;
      margin: 0 0 50px 0;
      text-align: center;
      color: #3F4040;
      font-size: 16px;
    }

    .content-cta-text {
      max-width: 730px;
      margin: 0 0 50px 0;
      color: $colorAccent3;
      text-align: center;

      p {
        color: $colorAccent3;

        strong {
          color: $colorAccent2;
        }
      }

      strong {
        color: inherit;
      }
    }
    .site-btn {
      display: inline-block;
      margin: 0 auto;
    }
  }

  &.is-dark {
    background: #2b2d2c;
    color: #fff;

    border-top: 1px solid rgba(#fff, .15);

    .content-cta-upper-text,
    .content-cta-heading,
    .content-cta-text {
      color: #fff;

      p {
        color: #fff;

        strong {
          color: #fff;
        }
      }
    }
  }
}


// --------------------------------------------------
// Members
// --------------------------------------------------
.site-members {
  text-align: center;
  .member-wrapper {
    @extend .site-block-ptpb;

    .members-heading {
      width: 100%;
      margin-bottom: 75px;
      text-align: center;
      color: #3F4040;
    }

    .members-listing {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: stretch;
      justify-content: center;
      background: transprent;

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }
  }
}

// --------------------------------------------------
// members Cards
// --------------------------------------------------
.member-card {
  margin: 0 50px;

  @media (max-width: 767px) {
    margin-bottom: 35px;
  }

  .member-img {
    position: relative;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    background: #f1f1f1;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }

  .member-card-heading {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 1.6;
    color: #595657;
  }

  .member-card-subtitle {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    max-width: 155px;
    color: #3F4040;
    font-size: 12px;
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .site-link-btn {
    font-size: 16px;
  }
}



// --------------------------------------------------
// News overview
// --------------------------------------------------
.site-news-overview {
  background: linear-gradient(180deg, #3D3F3F 0%, #2B2D2C 100%);
  color: #fff;

  .news-overview-wrapper {
    @extend .site-block-ptpb;

    .news-overview-heading {
      margin-top: 0;
      margin-bottom: 100px;

      @media (max-width: 767px) {
        margin-bottom: 45px;
      }
    }

    .news-listing {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: stretch;
      justify-content: center;
      margin-bottom: 50px;

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }

      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    .btn-wrapper {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-end;
    }
  }
}

// --------------------------------------------------
// News Cards
// --------------------------------------------------
.news-card {
  width: calc(100%/3);
  margin-right: 30px;
  background: #fff;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
    margin: 0 0 30px 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .news-card-img {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(63, 89, 103, 0.8);
      z-index: 1;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .news-card-content {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 20px 30px;
  }

  .news-card-date {
    margin-top: 0;
    margin-bottom: 20px;
    color: rgba(#3F4040, .5);
  }

  .news-card-heading {
    margin-top: 0;
    margin-bottom: 30px;
    color: #3F4040;
    font-weight: 400;
  }

  .site-link-btn {
    display: block;
    margin-top: auto;
    color: rgba(#3F4040, .5);
  }
}


.video-cover-wrapper {
  z-index: 1;
}

@media screen and (max-height: 599px) {

  .video-cover-wrapper {
    z-index: -1;
  }

  video {
    display: none;
  }
}

@media screen and (max-width: 629px) {
  .video-cover-wrapper {
    z-index: -1;
  }
  video {
    display: none;
  }
}
