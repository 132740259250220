.playground {



#main-nav-component,
.site-footer {
  display: none;
}


// Globals / Reset
&.site-container {
  margin: 0;
  padding: 0 0 100vh 0;
}

// --------------------------------------------------
// Intro / Test
// --------------------------------------------------
.block-intro {
  padding: 45px 100px;
  text-align: center;
  background: rgba(0, 0, 0, .05);

  h1 {
    margin: 0 0 25px 0;
    padding: 0;
    color: #333;
  }

  ul,
  ul li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.main-footer {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 45px 100px;
  background: rgba(0, 0, 0, .05);

  .footer-links {
    margin: 0 auto;
  }
}

// --------------------------------------------------
// Contact Support
// --------------------------------------------------
.contact-support-modal {
  padding: 10px;
  border-radius: 0;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);

  .alert-msg {
    p {
      margin: 0 0 10px;
    }

    &.error {
      p {
        color: #721c24;
      }
    }
  }

  div {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    color: #333;
  }

  h1 {
    text-align: center;
    font-weight: 400;
  }

  form {
    position: relative;
    width: 100%;
  }

  .control {
    input,
    textarea {
      width: 100%;
      padding: 15px;
      margin-bottom: 15px;
      border: none;
      border-bottom: 1px solid #f1f1f1;
      outline: none;
    }

    textarea {
      min-height: 100px;
    }
  }
}

// --------------------------------------------------
// Accordion
// --------------------------------------------------
.accordion-component {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 20px;
  color: rgba(#000, .65);

  .accordion-intro-heading {
    margin: 0 0 10px 0;
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    color: rgba(#000, .65);
  }

  .accordion-intro-text {
    margin: 0 0 35px 0;
    text-align: center;
    color: rgba(#000, .65);
    font-size: 14px;
  }

  .accordion-heading-wrapper {
    display: flex;
  }

  .icon-plus {
    position: relative;
    top: 2px;
    background: transparent;
    border: none;
    margin-right: 5px;

    cursor: pointer;

    path {
      fill: rgba(#000, .5);
    }
  }

  .accordion-heading {
    font-size: 22px;
    color: #000;

    cursor: pointer;
  }

  .accordion-text {
    position: relative;
    left: -2px;
    padding-left: 50px;
    margin-bottom: 35px;
    font-size: 14px;
    color: rgba(#000, 0.65);
  }
}

// --------------------------------------------------
// Nav Bar stick to top
// --------------------------------------------------

// Globals state
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  position: fixed;
  box-shadow: 0 5px 3px rgba(#333, 0.10);
}

.nav-bar {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f1f1f1;
  margin: 0;
  padding: 20px;
  color: #333;

  .nav-left {
    margin-right: auto;

    .nav-item {
      margin: 0;
    }
  }

  .nav-item {
    margin-left: 20px;
  }
}

// --------------------------------------------------
// Count
// --------------------------------------------------

.count-scope {
  padding: 45px 100px;
}

.count-row {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count-item {
  padding: 20px;
  color: #333;
  background: rgba(0, 0, 0, .10);
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 6px;
  margin: 0 20px;
  font-size: 18px;
}


// --------------------------------------------------
// Overlay CTA btn
// --------------------------------------------------
.overlay-cta-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, .10);
  border: 1px solid rgba(0, 0, 0, .05);
  text-align: center;
  line-height: 47px;
  color: #444;
  border-radius: 50%;
  font-size: 25px;
  font-weight: 400;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// --------------------------------------------------
// Dropdown
// --------------------------------------------------

#dropdown-scope {
  display: flex;
  align-items: center;
  justify-content: center;

  .dropdown {
    margin:0  25px;
  }
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(0);
  width: auto;
  margin-top: 25px;

  background: #FFFFFF;
  box-shadow: 0px 2px 40px rgba(15, 67, 83, 0.2);
  border-radius: 3px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);

    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;

    border-bottom: 7px solid #fff;
  }

  .dropdown-menu-header {
    border-bottom: 1px solid rgba(#0F4353, 0.1);

    h3 {
      position: relative;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 600;
      color: #0F4353;

      strong {
        font-weight: 800;
      }
    }

    .circle-count {
      position: absolute;
      top: -2px;
      right: 0;
      display: block;
      width: 20px;
      height: 20px;
      background: #498DA1;
      border-radius: 50%;
      color: #fff;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      text-indent: 1px;
    }
  }

  .dropdown-menu-header {
    padding: 20px 0;

    h3, ul, ul li, a {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: left;
    }

    h3, a {
      text-align: center;
      padding: 0 30px;
    }

    a {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: left;
    }
  }

  .dropdown-menu-content {

    ul li {
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: left;
      list-style-type: none;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: block;
        font-size: 14px;
        line-height: 1;
        padding: 15px 30px;
        text-align: left;

        color: #0F4353;

        text-shadow: 0px 2px 40px rgba(60, 81, 94, 0.111385);

        &:hover {
          background: rgba(0, 0, 0, .05);
        }

        img {
          margin-right: 20px;
        }
      }
    }
  }
}

.pop-out-quick-enter-active,
.pop-out-quick-leave-active {
  transition: all 0.4s ease;
}

.pop-out-quick-enter,
.pop-out-quick-leave-active {
  opacity: 0;
  transform: translateX(-50%) translateY(-7px);
}

// --------------------------------------------------
// Custom Modal
// --------------------------------------------------

.modal-overlay {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(15, 67, 83, 0.2);

  transition: opacity .35s ease;
  opacity: 0;

  &:target {
    opacity: 1;
    visibility: visible;
  }

  .modal-box {
    position: relative;
    width: 500px;
    max-width: 80%;
    background: #fff;
    border-radius: 4px;
    padding: 1em 2.5em;
    box-shadow: 0px 2px 40px rgba(15, 67, 83, 0.2);
  }

  .modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: grey;
    text-decoration: none;
  }

  .modal-cancel {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  footer {
    margin-top: 35px;
    margin-bottom: 25px;

    &:empty {
      display: none;
    }

    a {
      padding: 10px 15px;
      background: grey;
      color: #fff;
      border-radius: 6px;

      &:last-child {
        background: $colorAccent1;
      }
    }
  }
}

// --------------------------------------------------
// Tooltip
// --------------------------------------------------
.tooltip {
  width: 100%;
  max-width: 400px;
  background: #FFFFFF;
  box-shadow: 0px 2px 40px rgba(15, 67, 83, 0.2);
  border-radius: 3px;
  text-align: center;

  h3 {
    margin: 0 0 0 0;
    padding: 15px 30px;
    position: relative;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 600;
    color: #0F4353;

    strong {
      font-weight: 800;
    }
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1;
    padding: 0 30px 15px 30px;

    color: #0F4353;

    text-shadow: 0px 2px 40px rgba(60, 81, 94, 0.111385);
  }
}





}
