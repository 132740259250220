body {
  overflow-x: hidden;
}

.site-container {
  transition: all .3s ease-in-out;
}

.site-container.active {

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0,0,0,.65);
    opacity: 0;
  }

  @media all and (max-width: 1140px) {
    transform: translateX(-400px);

    &:before {
      opacity: 1;
    }
  }

  @media all and (max-width: 499px) {
    transform: translateX(-85%);
  }
}

.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(400px);
  transition: all 0.3s ease-in-out;
  background-color: #F0F0F0;

  @media all and (max-width: 1140px) {
    width: 400px;
    height: 100vh;
  }

  @media all and (max-width: 499px) {
    width: 85%;
  }

  &.is-active {
    @media all and (max-width: 1140px) {
      display: block;
      transform: translateX(0px);
    }
  }

  &.active {
    @media all and (max-width: 1140px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 499px) {
      width: 85%;
    }
  }

  // .navigation-alert {
  //   width: 100%;
  //   padding: 40px 30px;
  //
  //   .hero-account {
  //     display: inline-block;
  //     position: relative;
  //     z-index: 30000;
  //
  //     img,
  //     div {
  //       display: inline-block;
  //     }
  //
  //     img {
  //       margin-right: 10px;
  //       margin-bottom: 5px;
  //     }
  //
  //     .select-account {
  //       font-family: $fontText;
  //       font-style: normal;
  //       font-weight: normal;
  //       font-size: 16px;
  //       line-height: 19px;
  //       background-color: transparent;
  //       border: none;
  //       // width: 100%;
  //       min-width: 100px;
  //       box-sizing: border-box;
  //       margin: 0;
  //       -moz-appearance: none;
  //       -webkit-appearance: none;
  //       appearance: none;
  //       background-image: url('/img/icons/arrow-down.svg');
  //       background-repeat: no-repeat, repeat;
  //       background-position: right 5px top 50%, 0 0;
  //       background-size: .65em auto, 100%;
  //     }
  //   }
  //
  //   .hero-alert {
  //     position: relative;
  //     display: inline-block;
  //     margin-right: 60px;
  //
  //     img {
  //       margin-bottom: 5px;
  //     }
  //
  //     .alert {
  //       display: block;
  //       position: absolute;
  //       top: 2px;
  //       right: 2px;
  //       background-color: #F82558;
  //       border-radius: 1px;
  //       font-family: $fontText;
  //       font-style: normal;
  //       font-weight: 500;
  //       font-size: 9px;
  //       line-height: 11px;
  //       color: $white;
  //       max-width: 10px;
  //       max-height: 13px;
  //       text-align: center;
  //       padding: 2px;
  //       margin: 0;
  //
  //       &.is-checked {
  //         display: none;
  //       }
  //     }
  //   }
  // }



  .navigation-header {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    .logo {
      height: 40px;
    }
  }

  .navigation-close {
    flex-grow: 1;
    text-align: right;
    font-size: 28px;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 40px 30px;

    a {
      margin-bottom: 15px;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: $colorAccent1;
    }
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 15px;
    border-bottom: 2px solid $alternateWhite;

    a {
      margin-bottom: 15px;
      color: $dark;
      font-size: 10px;
      text-transform: uppercase;

      &.site-btn {
        color: white;
      }
    }
  }

  .navigation-footer {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    a {
      //@extend .link;
      font-size: 13px;
      padding-left: 20px;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
    }
  }

  .navigation-footer-social {
    flex-grow: 1;

    a {
      padding: 0;
    }

    img {
      padding-right: 20px;
      height: 14px;
      transition: all .3s ease-in-out;
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
