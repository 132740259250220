.restriction-popup-box {
  position: fixed;
  bottom: 24px;
  right: 32px;

  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  text-align: center;

  max-width: 250px;
  padding: 24px 15px;

  background: $white;
  border-radius: 10px;

  z-index: 3;

  .close-restriction-warning {
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
  }

  p {
    margin: 15px 0;
    font-size: 14px;
    color: $colorAccent4;

    &.js-restriction-trigger {
      margin: 0;
      color: $colorAccent1;
      cursor: pointer;
    }
  }
}

.restriction-popup-wrapper {
  position: fixed;
  top: 0;
  right: -345px;

  display: flex;
  flex-direction: column;
  align-items:center;

  max-width: 345px;
  min-height: 100vh;
  padding: 62px 25px 50px;
  background: $white;
  transition: all .5s ease-in-out;
  z-index: 99999;

  &.is-opened {
    right: 0;
  }

  .restriction-popup-content {
    max-height: 80vh;
    overflow:scroll;
  }

  .big-title {
    margin-bottom: 50px;
    font-family: $fontTitle;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 37px;
    color: $colorAccent4;
    text-transform: uppercase;
  }

  .restriction-rule {
    display: flex;
    margin: 0 0 48px;

    .restriction-number {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      width: 31px;
      height: 31px;
      background: $colorAccent1;
      border-radius: 50%;
      margin: 0;

      color: $white;
      font-size: 15px;
      font-family: $fontText;
    }

    .regular-text {
      padding-left: 15px;
      font-size: 14px;
      color: $colorAccent4;
      margin: 0;
    }
  }
}

.restriction-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc(100%);
  background: rgba(0,0,0,.0);
  z-index: -1;
  transition: all 0.3s ease-in;

  &.is-opened {
    z-index: 9999;
    background: rgba(0,0,0,.5);
  }
}
