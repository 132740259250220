// --------------------------------------------------
// Block Grid001
// --------------------------------------------------

.grid001 {
  padding: 60px 20px;
  display: flex;
  justify-content: center;

  .grid001-container {
    width: 100%;
    max-width: 1024px;
  }

  .grid-heading-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.align-left {
      align-items: flex-start;

      .grid001-text {
        text-align: left;
      }
    }
    &.align-right {
      align-items: flex-end;

      .grid001-text {
        text-align: right;
      }
    }
  }

  .grid001-heading {
    @extend .big-title;
    margin: 0 0 20px;
  }

  .grid001-text {
    @extend .regular-text;
    text-align: center;
    max-width: 800px;

    p {
      margin: 0;
    }
  }

  .grid001-items {
    display: flex;
    flex-wrap: wrap;

    &.columns-4 .grid-item {
      width: calc(25% - (60px / 4));

      &:nth-child(4n+4) {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        width: calc(50% - 10px);

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }

      @media (max-width: 520px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &.columns-3 .grid-item {
      width: calc(33.33334% - (40px / 3));

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        width: calc(50% - 10px);

        &:nth-child(3n+3) {
          margin-right: 20px;
        }

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }

      @media (max-width: 520px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &.columns-2 .grid-item {
      width: calc(50% - 10px);

      &:nth-child(2n+2) {
        margin-right: 0;
      }

      @media (max-width: 520px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &.empty-mode {
      &.columns-3 .grid-item {
        &:nth-child(n+7) {
          display: none;
        }
      }
      &.columns-2 .grid-item {
        &:nth-child(n+3) {
          display: none;
        }
      }
    }
  }

  .grid-item {
    margin-right: 20px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image, img {
      height: 60px;
      margin-bottom: 20px;
    }

    .item-heading {
      @extend .medium-title;
      margin: 0 0 10px;
    }

    .item-text {
      @extend .regular-text;
      text-align: center;
      max-width: 300px;

      p {
        margin: 0;
      }
    }

    &.align-left {
      align-items: flex-start;

      .item-text {
        text-align: left;
      }
    }
    &.align-right {
      align-items: flex-end;

      .item-text {
        text-align: right;
      }
    }
  }

  &.grey {
    background-color: $grey;
  }
  &.dark {
    background-color: $dark;

    .grid001-heading, .grid-item .item-heading {
      color: white;
    }
  }
}
