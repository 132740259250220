// --------------------------------------------------
// Block Split002
// --------------------------------------------------

.split002 {
  background: url('/img/site/blog-thumbnail-default.svg') no-repeat center center / cover;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .split002-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }

  .split002-textbox {
    position: relative;
    width: 100%;
    min-height: 1px;
    flex: 0 0 100%;
    max-width: 100%;
    background-color: white;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 576px) {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (min-width: 992px) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 100px 80px;
    }
  }

  .split002-heading {
    @extend .medium-title;
    margin-top: 0;
  }

  .split002-text {
    @extend .regular-text;
    text-align: center;
  }

  .site-btn {
    margin-top: 20px;
  }

  // Options
  &.grey {
    .split002-container .split002-textbox {
      background-color: $grey;
    }
  }
  &.dark {
    .split002-container .split002-textbox {
      background-color: $dark;

      .split002-heading {
        color: white;
      }
    }
  }

  &.left {
    .split002-container .split002-textbox {
      align-items: flex-start;

      .split002-text {
        text-align: left;
      }
    }
  }
  &.right {
    .split002-container .split002-textbox {
      align-items: flex-end;

      .split002-text {
        text-align: right;
      }
    }
  }

  &.boxOnTheRight {
    .split002-container {
      justify-content: flex-end;
    }
  }
}
