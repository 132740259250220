.banners-create-order {
  .banners-create-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 57px;
    margin-bottom: 100px;

    .page-header {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 50px 20px;
      background: $colorAccent4;

      .header-heading {
        flex-shrink: 0;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 1.5em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: center;
        margin: 0;
      }
    }

    .page-content {
      width: 100%;
      max-width: 580px;
      padding: 80px 20px;
      display: flex;
      flex-direction: column;

      .content-step {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 70px;

        .step-suptitle {
          font-family: $fontTitleRock;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 1.2em;
          text-align: center;
          text-transform: uppercase;
          color: $colorAccent1;
          margin: 0 0 6px;
        }

        .step-title {
          font-family: $fontTextLight;
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 1.15em;
          text-align: center;
          color: $colorAccent4;
          margin: 0 0 40px;
        }

        .banners-instructions {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 40px;

          &.is-active {
            .instructions-toggle:after {
              background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOCAzOCI+PGNpcmNsZSBjeD0iMTkiIGN5PSIxOSIgcj0iMTkiIGZpbGw9IiMwMDhDRDMiLz48cGF0aCBkPSJNNyAxOC41QTEuNSAxLjUgMCAwIDEgOC41IDE3aDIxYTEuNSAxLjUgMCAwIDEgMCAzaC0yMUExLjUgMS41IDAgMCAxIDcgMTguNVoiIGZpbGw9IiNGNEY0RjQiLz48L3N2Zz4=");
            }

            .instructions-content {
              display: block;
            }
          }

          .instructions-toggle {
            position: relative;
            font-family: $fontTitleRock;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 40px;
            color: $colorAccent4;
            padding-right: 60px;
            margin: 0;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

            &:after {
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              width: 40px;
              height: 40px;
              background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOCAzOSI+PHBhdGggZD0iTTE5IC42MjhjLTEwLjQ5NCAwLTE5IDguNTA4LTE5IDE5IDAgMTAuNDk0IDguNTA2IDE5IDE5IDE5czE5LTguNTA2IDE5LTE5LTguNTA2LTE5LTE5LTE5Wm0xMC4yNiAyMC40NTloLTguODAydjguODAyYTEuNDU5IDEuNDU5IDAgMCAxLTIuOTE2IDB2LTguODAySDguNzRhMS40NTkgMS40NTkgMCAwIDEgMC0yLjkxN2g4LjgwMVY5LjM2OGExLjQ1OSAxLjQ1OSAwIDAgMSAyLjkxOCAwdjguODAyaDguODAxYTEuNDU5IDEuNDU5IDAgMCAxIDAgMi45MTdaIiBmaWxsPSIjMDA4Q0QzIi8+PC9zdmc+");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
            }
          }

          .instructions-content {
            display: none;
            background: #FFFFFF;
            border-radius: 8px;
            padding: 20px;
            font-family: $fontText;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5em;
            color: $colorAccent4;
            margin: 18px 0 0;
          }
        }
      }
    }

    .bottom-line {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: $colorAccent4;

      .bottom-line-container {
        margin: 0 auto;
        padding: 20px;
        width: 100%;
        max-width: 1100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bottom-total {
          font-family: $fontTitleRock;
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 1.5em;
          text-transform: uppercase;
          color: #FFFFFF;
          margin: 0;
        }

        .bottom-button {
          background-color: #FFFFFF;
          padding: 10px 30px;
          border-radius: 6px;
          font-family: $fontText;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.2em;
          text-align: center;
          color: $colorAccent4;
          appearance: none;
          outline: none;
          border: none;
          cursor: pointer;
        }
      }
    }

    .product-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      &.is-template {
        display: none;
      }

      .delete-button-section {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 10px 0 0;

        &.is-hidden {
          display: none;
        }

        .delete-button {
          font-family: $fontText;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5em;
          text-align: right;
          color: $colorAccent4;
          cursor: pointer;
          margin: 0;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .add-button-section {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .add-button {
        position: relative;
        font-family: $fontTitleRock;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 40px;
        color: $colorAccent4;
        padding-right: 60px;
        margin: 0;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 40px;
          height: 40px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOCAzOSI+PHBhdGggZD0iTTE5IC42MjhjLTEwLjQ5NCAwLTE5IDguNTA4LTE5IDE5IDAgMTAuNDk0IDguNTA2IDE5IDE5IDE5czE5LTguNTA2IDE5LTE5LTguNTA2LTE5LTE5LTE5Wm0xMC4yNiAyMC40NTloLTguODAydjguODAyYTEuNDU5IDEuNDU5IDAgMCAxLTIuOTE2IDB2LTguODAySDguNzRhMS40NTkgMS40NTkgMCAwIDEgMC0yLjkxN2g4LjgwMVY5LjM2OGExLjQ1OSAxLjQ1OSAwIDAgMSAyLjkxOCAwdjguODAyaDguODAxYTEuNDU5IDEuNDU5IDAgMCAxIDAgMi45MTdaIiBmaWxsPSIjMDA4Q0QzIi8+PC9zdmc+");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
      }
    }
  }

  .banners-cart-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 57px;
    margin-bottom: 100px;

    .page-header {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 50px 20px;
      background: $colorAccent4;

      .header-heading {
        flex-shrink: 0;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 1.5em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: center;
        margin: 0;
      }
    }

    .page-content {
      width: 100%;
      max-width: 1000px;
      padding: 80px 20px;
      display: flex;
      flex-direction: column;

      .cart-heading {
        font-family: $fontTitleRock;
        font-style: normal;
        font-weight: 700;
        font-size: 33px;
        line-height: 1.3em;
        color: $colorAccent4;
        margin: 0 0 40px;
      }

      .cart-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        .cart-item {
          width: calc((100% - 40px) / 3);
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: 3px;

          &:nth-child(3n+3) {
            margin-right: 0;
          }

          &.add-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 450px;
            background-color: $colorAccent4;

            svg {
              height: 38px;
              margin-bottom: 12px;
            }

            .add-item-heading {
              font-family: $fontTitleRock;
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 1.3em;
              color: #FFFFFF;
              margin: 0;
            }
          }

          &.banner-item {
            padding: 20px;
            background: #FFFFFF;
            display: flex;
            flex-direction: column;

            .item-heading {
              font-family: $fontTitleRock;
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 1.3em;
              color: $colorAccent4;
              margin: 0 0 4px;
            }

            .item-size {
              font-family: $fontText;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 1.2em;
              color: $colorAccent4;
              margin: 0 0 4px;
            }

            .item-season {
              font-family: $fontText;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: $colorAccent4;
              margin: 0 0 32px;
            }

            .item-details {
              font-family: $fontTextLight;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 1.15em;
              color: $colorAccent1;
              margin: 0 0 12px;

              strong {
                color: $colorAccent4;
              }

              &.column {
                display: flex;
                flex-direction: column;
              }
            }

            .item-price {
              width: 100%;
              text-align: right;
              margin: 8px 0 16px;
              font-family: $fontTextLight;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 1.2em;
              color: $colorAccent4;
            }

            .item-update {
              font-family: $fontText;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 1.2em;
              text-decoration-line: underline;
              color: $colorAccent1;
              margin: 0;
            }
          }
        }
      }
    }

    .bottom-line {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: $colorAccent4;

      .bottom-line-container {
        margin: 0 auto;
        padding: 20px;
        width: 100%;
        max-width: 1100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bottom-total {
          font-family: $fontTitleRock;
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 1.5em;
          text-transform: uppercase;
          color: #FFFFFF;
          margin: 0;
        }

        .bottom-button {
          background-color: #FFFFFF;
          padding: 10px 30px;
          border-radius: 6px;
          font-family: $fontText;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.2em;
          text-align: center;
          color: $colorAccent4;
          appearance: none;
          outline: none;
          border: none;
          cursor: pointer;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .product-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      &.is-template {
        display: none;
      }

      .delete-button-section {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 10px 0 0;

        &.is-hidden {
          display: none;
        }

        .delete-button {
          font-family: $fontText;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5em;
          text-align: right;
          color: $colorAccent4;
          cursor: pointer;
          margin: 0;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .add-button-section {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .add-button {
        position: relative;
        font-family: $fontTitleRock;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 40px;
        color: $colorAccent4;
        padding-right: 60px;
        margin: 0;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 40px;
          height: 40px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOCAzOSI+PHBhdGggZD0iTTE5IC42MjhjLTEwLjQ5NCAwLTE5IDguNTA4LTE5IDE5IDAgMTAuNDk0IDguNTA2IDE5IDE5IDE5czE5LTguNTA2IDE5LTE5LTguNTA2LTE5LTE5LTE5Wm0xMC4yNiAyMC40NTloLTguODAydjguODAyYTEuNDU5IDEuNDU5IDAgMCAxLTIuOTE2IDB2LTguODAySDguNzRhMS40NTkgMS40NTkgMCAwIDEgMC0yLjkxN2g4LjgwMVY5LjM2OGExLjQ1OSAxLjQ1OSAwIDAgMSAyLjkxOCAwdjguODAyaDguODAxYTEuNDU5IDEuNDU5IDAgMCAxIDAgMi45MTdaIiBmaWxsPSIjMDA4Q0QzIi8+PC9zdmc+");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
      }
    }
  }

  .banners-thank-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 57px;
    margin-bottom: 100px;

    .page-header {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 50px 20px;
      background: $colorAccent4;
      min-height: 175px;

      svg {
        height: 105px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
      }
    }

    .page-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 100px 0 40px;

      .content-heading {
        font-family: $fontTitleRock;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 1.2em;
        text-align: center;
        text-transform: uppercase;
        color: $colorAccent1;
        margin: 0 0 30px;
      }

      .content-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 1.15em;
        text-align: center;
        color: $colorAccent4;
        margin: 0 0 30px;
      }

      .content-button {
        background: $colorAccent1;
        border-radius: 6px;
        padding: 10px 30px;
        font-family: $fontText;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2em;
        text-align: center;
        color: #FFFFFF;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .form-label {
    font-family: $fontText;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    color: $colorAccent4;
    margin: 0 0 4px;
  }

  .form-input {
    width: 100%;
    padding: 12px 16px;
    background-color: #FFFFFF;
    border-radius: 6px;
    font-family: $fontText;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2em;
    color: $colorAccent4;
    margin: 0 0 14px;
    appearance: none;
    border: none;
    outline: none;

    &::placeholder {
      color: $colorAccent4;
    }
  }

  .form-textarea {
    @extend .form-input;
    min-height: 100px;
  }

  .form-date {
    @extend .form-input;
  }

  .form-select {
    @extend .form-input;
  }

  .form-error-text {
    font-family: $fontText;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2em;
    color: $colorM10;
    margin: -8px 0 14px;
  }
}

.banners-create-order,
.banners-create-wrapper {
  .bottom-line {
    .bottom-line-container {
      .btns-wrapper {
        display: flex;
        align-items: center;
        gap: 18px;

        .bottom-button {
          width: fit-content;
        }

        .bottom-button {
          &.is-reverted {
            background: transparent;
            border: 1px solid $light;
            color: $light;
            margin-right: 18px;
            transition: all 0.3s ease-in-out;

            &:hover {
              opacity: 1;
              background: $light;
              color: $colorAccent4;
            }
          }
        }
      }

      form {
        margin: 0;
      }
    }
  }
}
