.commandes-vetements-wrapper .vetements-form {

  .vetements-form-container.logo-form {
    margin-bottom: 20px;
    padding-bottom: 0;
  }

  .cart-title {
    text-align: left;
    color: $colorAccent4;
    margin-bottom: 40px;
  }

  .logo-input-wrapper {
    .regular-text {
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 167.19%;
      color: $colorAccent4;
    }

    .input-disciplines-wrapper {
      cursor: pointer;

      img {
        max-width: 250px;
      }

      .file-input {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          cursor: pointer;
          &:hover {
            opacity: .6;
          }
        }

        .site-btn {
          width: auto;
        }

        .js-file-name {
          margin-left: 15px;
          color: $colorAccent5;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 167.2%;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        background: transparent;
      }
    }
  }
}

.clothes-order {
  max-width: 1170px;
  padding: 0 30px 80px;
  margin: 0 auto 64px;
  margin-bottom: 100px;
}

.clothes-order-wrapper {
  display: flex;
  margin-top: 45px;
  flex-wrap: wrap;
}

.entry-warning {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 85%;
  height: fit-content;
  background: $white;
  padding: 15px;
  z-index: 2;
  border-radius: 10px;

  svg {
    margin-top: 15px;
    flex-shrink: 0;
  }

  p {
    font-family: $fontText;
    color: $colorAccent4;
  }

  .site-link-btn {
    display: block;
    margin: 10px auto 0;
    font-size: 16px;
    text-decoration: underline;
    background: transparent;
    border:none;
    color: $colorAccent1;
    pointer-events: auto;

    &:hover {
      color: darken($colorAccent1, 10%);
    }
  }

  button.site-link-btn {
    color: #d81a1a;

    &:hover {
      color: darken(#d81a1a, 12%);
    }
  }
}

.clothes-entry-wrapper {
  position: relative;
  width: calc((100% / 3) - 22px);
  padding: 22px;
  margin-right: 22px;
  margin-bottom: 22px;
  background: $white;

  @media (max-width: 1024px) {
    width: calc((100% / 2) - 22px);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }

  &.add-entry {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $colorAccent4;
    transition: opacity .3s ease-in-out;
    margin-right: 0;

    &.is-fixed {
      position: fixed;
      bottom: 90px;
      right: 32px;
      z-index: 3;

      border-radius: 10px;
      max-width: 250px;

      @media (max-width: 991px) {
        bottom: 145px;
      }

      p {
        margin-bottom: 0;
      }
    }

    &:hover {
      opacity: .9;
    }

    p{
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: $white;
      margin-bottom: 0;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &.is-disabled {
    background: $light;
    z-index: 1;

    .clothes-entry-content {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .small-title {
    margin: 0;
    color: $colorAccent4;
  }

  .small-text {
    margin-top: 5px;
    color: $colorAccent4;
  }

  .entry-image {
    text-align: center;

    .regular-text {
      font-size: 16px;
      line-height: 130%;
      margin: 10px 0 28px;
      color : $colorAccent4;
    }

    img {
      max-width: 100%;
    }
  }

  .entry-sizes-wrapper {
    display: flex;
    flex-wrap: wrap;

    .entry-size {
      font-family: $fontText;
      font-size: 14px;
      margin: 0 28px 10px 0;
      font-weight: bold;
      color: $colorAccent4;

      span {
        color: $colorAccent1;
      }
    }
  }

  .entry-total-wrapper {
    display: flex;
    margin: 25px 0 16px;

    p {
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: $colorAccent4;

      span {
        margin-left: 5px;
        color: $colorAccent1;
      }
    }

    .entry-total-price {
      margin-left: auto;
    }
  }

  .entry-options {
    display: flex;
    //align-items: center;

    .site-link-btn {
      font-size: 16px;
      text-decoration: underline;
    }

    button.site-link-btn {
      font-size: 13px;
      margin-left: auto;
      background: transparent;
      border:none;
      color: #d81a1a;

      &:hover {
        color: darken(#d81a1a, 12%);
      }
    }
  }
}

.cart-summary {
  button.js-tooltip-qty.site-btn {
    pointer-events: none;
    background: #D7D7D7 !important;
  }
}

.edit-title {
  margin-bottom: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 44px;
  color: $colorAccent4;
}

#tooltip {
  background: $white;
  color: $colorAccent4;
  font-family: $fontText;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid $colorAccent4;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  pointer-events: none;
  //transform: translate3d(342px, -50px, 0px) !important;
}

#tooltip[data-show] {
  opacity: 1;
}


#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}

#tooltip {
  background-color: $white;
  color: $colorAccent4;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
}

