.info-wrapper {
  background: #F4F4F4;
}

.info-form-wrapper {
  max-width: 1024px;
  margin: auto;

  &.signup-form {
    padding: 10px 0 180px;
    max-width: 540px;
    background: transparent;

    .medium-title {
      margin: 63px 0 32px;
      font-family: $fontText;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 29px;
    }

    .form-title, label {
      text-transform: none;
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      text-align: left;
      color: $colorAccent4;
      margin-bottom: 10px;
    }

    input, textarea,
    select {
      padding-left: 15px;
      border: none;
      background: $white;
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      border-radius: 6px;
    }

    select {
      padding-right: 15px;
    }

    .textarea-input {
      display: flex;
      flex-direction: column;
      margin-top: 38px;

      textarea {
        padding: 15px 18px;
        color: $colorAccent4;
        max-width: 540px;
        min-height: 100px;
      }
    }
  }
}
