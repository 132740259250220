// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitle: 'oswald', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTitleSerif: 'oswald', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontText: 'Roboto Condensed', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTextLight: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTitleRock: 'rockwell', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #008CD3;
$colorAccent2: #164C70;
$colorAccent3: #164C70;
$colorAccent4: #073881;
$colorAccent5: #8398BF;

// Texts
$colorTextDark: #3F4040;
$colorTextRegular: #3F4040;
$colorTextLight: #fff;

// Blocks and Text
$dark: #3F4040;
$grey: #F8F8F8;
$light: #F8F8F8;
$lighter: #F8F8F8;
$alternateWhite: #F8F8F8;
$white : #fff;


















//Modules Colors
$colorM1: #13AEEE ;
$colorM2: #13AEC3 ;
$colorM3:#13AE87;
$colorM4:#4FD3B0;
$colorM5:#4FD389;
$colorM6:#4FD360;
$colorM7:#FE874E;
$colorM8:#FE8777;
$colorM9:#FE87BE;
$colorM10:#CF065A;
$colorM11:#CF068E;
$colorM12:#CF06C8;
$colorM13:#845AB8;
$colorM14:#845AD8;
$colorM15:#845AF6;
