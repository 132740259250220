

//Footer
.site-footer {
  background-color: $dark;
  padding: 60px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    padding: 40px 0;
  }

  .footer-container {
    @extend .site-max-width;

    width: 100%;
    margin: 0 auto 65px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .footer-logo {
    max-width: 200px;
    max-height: 100%;
    width: 100%;
    height: auto;
  }

  .footer-column {
    width: calc(25% - ((60px * 3) / 4));

    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer-title {
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .5px;
    font-weight: 600;
    color: #929292;
    text-transform: uppercase;
    padding: 0 0 10px;
    border-bottom: 1px solid #525252;
    margin: 0 0 10px;
  }

  .footer-text {
    font-family: $fontTitle;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: .5px;
    color: white;
    margin: 0 0 20px;
  }

  .footer-newsletter-form {
    display: flex;
    margin-bottom: 20px;

    input[type=email] {
      flex-grow: 1;
      padding: 10px 15px;
      border: none;
      outline: 0;
      line-height: 24px;
    }

    button[type=submit] {
      padding: 10px 15px;
      border: none;
      outline: 0;
      font-weight: 700;
      color: white;
      line-height: 24px;
      background-color: $colorAccent1;
    }
  }

  .nav-link {
    display: block;
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .5px;
    font-weight: 500;
    color: white;
    padding: 12px 0;
    transition: color ease-out .2s;

    i {
      margin-right: 12px;
      font-size: 16px;
    }

    &:hover {
      color: $colorAccent1;
    }
  }

  .footer-bottom {
    display: flex;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: .5px;
    color: rgba(white, .75);
    font-weight: 500;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-legal {
    margin: 0 10px 0 0;
    border-right: 1px solid white;
    padding-right: 10px;

    @media (max-width: 1023px) {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .footer-rights {
    color: rgba(white, .75);
    font-weight: 500;
    transition: color ease-out .2s;
    border-right: 1px solid white;
    padding-right: 10px;
    margin-right: 10px;

    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    @media (max-width: 1023px) {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    &:hover {
      color: $colorAccent1;
    }
  }
}
